<template>
  <div>
    <u-table
        ref="table"
        color="primary"
        :data="table.serverData"
        :columns="table.columns"
        :visible-columns="visibledColumns"
        :filter="table.busca"
        row-key="id"
        :pagination.sync="table.serverPagination"
        @request="request"
        :loading="table.loading"
        class="erp-table table-v2"
        :hide-bottom="Number(table.serverPagination.rowsNumber) < table.serverPagination.rowsPerPage"
        :rows-per-page-options="[20, 50, 100]"
    >
      <!--            <template slot="top" slot-scope="props">
                  </template>-->
      <u-tr slot="header" slot-scope="props">
        <!--        <u-th auto-width v-if="mode !== 'cliente'">
                  <u-checkbox
                      v-model="props.selected"
                      :indeterminate="props.partialSelected"
                      size="xs"
                  />
                </u-th>-->
        <u-th v-for="col in props.cols" :key="col.name" :props="props" :class="'col-' + col.name">
          <div v-if="col.name === 'options'" class="text-center">
            <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat no-caps
                   size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
          </div>
          <template v-else>{{ col.label }}</template>
        </u-th>
      </u-tr>
      <u-tr slot="body" slot-scope="props" :props="props">
        <u-td key="id" :props="props">
          {{ props.row.id }}
        </u-td>
        <u-td key="dataInicio" :props="props">
          {{ props.row.dataInicio|formatDate('dd/MM/yyyy') }}
        </u-td>
        <u-td key="dataFim" :props="props">
          {{ props.row.dataTermino|formatDate('dd/MM/yyyy') }}
        </u-td>
        <u-td key="descricao" :props="props">
          <router-link :to="{name: 'suporte.cliente.contrato', params: {id: props.row.contrato?.id}}">{{ props.row.contrato?.produto?.nome }}</router-link>
          <div class="font-11 text-grey">Início em {{ props.row.dataInicio|formatDate('dd/MM/yyyy') }}</div>
        </u-td>
        <u-td key="criterioCobranca" :props="props">
          <div v-if="props.row.contrato?.produto?.criterioCobranca === 2">
            De acordo uso
          </div>
          <div v-else>
            Recorrente
          </div>
        </u-td>
        <u-td key="valor" :props="props">
          <div v-if="props.row.contrato?.produto?.criterioCobranca === 2">
            R$ {{ props.row.contrato?.produto?.valor|moeda2 }}
            <div class="font-11 text-grey" v-if="props.row.contrato?.produto?.unidadeMedidaCobrancaDescricao">
              {{ props.row.contrato?.produto?.unidadeMedidaCobrancaDescricao }}
            </div>
          </div>
          <div v-else>
            R${{ props.row.valor|moeda }}
            <div class="font-11 text-grey">{{ periodicidadeString(props.row.periodicidade) }}</div>
          </div>
        </u-td>
        <u-td key="dataProximoVencimento" :props="props">
          {{ props.row.dataProximoVencimento|formatDate('dd/MM/yyyy') }}
        </u-td>
        <u-td key="status" :props="props">
          <div v-if="atraso(props.row.dataProximoVencimento) > 0" class="text-negative">
            {{ atraso(props.row.dataProximoVencimento) }} dias em atraso
          </div>
          <div v-if="props.row.contrato?.status === 0" class="text-grey">Pendente</div>
          <div v-if="props.row.contrato?.status === 1" class="text-green">Ativo</div>
          <div v-if="props.row.contrato?.status === 98">Expirado</div>
          <div v-if="props.row.contrato?.status === 99" class="text-negative">Cancelado</div>
          <div v-if="props.row.contrato?.status === 100" class="text-grey">Encerrado</div>
        </u-td>
        <u-td key="detalhes" :props="props">
          <a v-if="props.row.active" @click="cancelarAssinatura(props.row.id)" class="text-negative">Cancelar</a>
        </u-td>
      </u-tr>
    </u-table>
    <div v-if="Number(table.serverPagination.rowsNumber === 0)">Nenhum registro até o momento.</div>
  </div>
</template>

<script>

import {listName, listStorage} from "components/suporte/cliente/config/listAssinaturas"
import filters from "@/domain/tarefas/helpers/filtersCliente"
import listOptions from "components/usuario/v2/window/listOptions"
import {UTable, UTh, UTr, UTd, date, LocalStorage} from 'uloc-vue'
import {datePtToEn} from "@/utils/date"
import {assinaturas as list} from "@/domain/financeiro/services"
import AssinaturaHelperMixin from "components/suporte/cliente/components/include/assinaturaHelperMixin"

export default {
  name: "ClienteAssinaturas",
  beforeCreate() {
    this.listStorage = listStorage
  },
  mixins: [AssinaturaHelperMixin],
  data() {
    return {
      listName: listName,
      listStorage: this.listStorage,
      response: {},
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 1, // specifying this determines pagination is server-side
          rowsPerPage: 100
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        })/*.concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ])*/,
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      },
    }
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  components: {
    UTable,
    UTh,
    UTr,
    UTd,
  },
  mounted() {
    this.load()
  },
  activated() {
    //this.$route.name === 'suporte.cliente.faturas' && this.load()
  },
  watch: {},
  methods: {
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || (!this.table.filters.data2 || this.table.filters.data2.length < 10)) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)
      this.table.filters.search && extraFilters.push(`&search=${String(this.table.filters.search).replace('#', '_hash-')}`)
      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.active && extraFilters.push(`&active=${this.table.filters.active}`)
      this.table.filters.status && extraFilters.push(`&status=${this.table.filters.status.id}`)

      if (this.extraFilters && Array.isArray(this.extraFilters)) {
        this.extraFilters.map(ef => {
          extraFilters.push('&' + ef)
        })
      }

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'dataInicio'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : true}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, false)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
            this.$emit('load', data)
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set(this.listName, newConfig)
      this.listStorage = newConfig
    }
  }
}
</script>
