<template>
  <div>
          <u-table
              ref="table"
              color="primary"
              :data="table.serverData"
              :columns="table.columns"
              :visible-columns="visibledColumns"
              :filter="table.busca"
              row-key="id"
              :pagination.sync="table.serverPagination"
              @request="request"
              :loading="table.loading"
              class="erp-table table-v2"
              :hide-bottom="Number(table.serverPagination.rowsNumber) < table.serverPagination.rowsPerPage"
              :rows-per-page-options="[20, 50, 100]"
          >
            <!--            <template slot="top" slot-scope="props">
                        </template>-->
            <u-tr slot="header" slot-scope="props">
              <!--        <u-th auto-width v-if="mode !== 'cliente'">
                        <u-checkbox
                            v-model="props.selected"
                            :indeterminate="props.partialSelected"
                            size="xs"
                        />
                      </u-th>-->
              <u-th v-for="col in props.cols" :key="col.name" :props="props" :class="'col-' + col.name">
                <div v-if="col.name === 'options'" class="text-center">
                  <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
                </div>
                <template v-else>{{col.label}}</template>
              </u-th>
            </u-tr>
            <u-tr slot="body" slot-scope="props" :props="props">
              <u-td key="id" :props="props">
                {{ props.row.id }}
              </u-td>
              <u-td key="dataInicio" :props="props">
                {{ props.row.dataInicio|formatDate('dd/MM/yyyy') }}
              </u-td>
              <u-td key="dataFim" :props="props">
                {{ props.row.dataFim|formatDate('dd/MM/yyyy') }}
              </u-td>
              <u-td key="descricao" :props="props">
                {{ props.row.produto.nome }}
              </u-td>
              <u-td key="criterioCobranca" :props="props">

                <div v-if="props.row.produto.criterioUsos && props.row.produto.criterioUsos.length">De acordo uso <span v-if="props.row.produto.unidadeMedidaCobrancaDescricao">({{props.row.produto.unidadeMedidaCobrancaDescricao}})</span></div>
                <div v-else>
                  R$ {{props.row.produto.valor|moeda2}}
                  <span v-if="props.row.produto.unidadeMedidaCobrancaDescricao">({{props.row.produto.unidadeMedidaCobrancaDescricao}})</span>
                </div>
              </u-td>
              <u-td key="valor" :props="props">
                R${{ props.row.valorContrato|moeda }}
              </u-td>
              <u-td key="status" :props="props">
                <div v-if="props.row.status === 0" class="text-orange-10">Pendente</div>
                <div v-if="props.row.status === 1" class="text-green">Ativo</div>
                <div v-if="props.row.status === 98">Expirado</div>
                <div v-if="props.row.status === 99" class="text-negative">Cancelado</div>
                <div v-if="props.row.status === 100" class="text-grey">Encerrado</div>
              </u-td>
              <u-td key="detalhes" :props="props">
                <router-link :to="{name: 'suporte.cliente.contrato', params: {id: props.row.id}}">Detalhes</router-link>
              </u-td>
            </u-tr>
          </u-table>
          <div v-if="Number(table.serverPagination.rowsNumber === 0)">Nenhum registro até o momento.</div>
        </div>
</template>

<script>

import {listName, listStorage} from "components/suporte/cliente/config/listContratos"
import filters from "@/domain/tarefas/helpers/filtersCliente"
import listOptions from "components/usuario/v2/window/listOptions"
import {UTable, UTh, UTr, UTd, date, LocalStorage} from 'uloc-vue'
import {datePtToEn} from "@/utils/date"
import {contratos as list} from "@/domain/financeiro/services"

export default {
  name: "ClienteContratos",
  beforeCreate() {
    this.listStorage = listStorage
  },
  data () {
    return {
      listName: listName,
      listStorage: this.listStorage,
      response: {},
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 1, // specifying this determines pagination is server-side
          rowsPerPage: 100
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        })/*.concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ])*/,
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      },
    }
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  components: {
    UTable,
    UTh,
    UTr,
    UTd,
  },
  mounted() {
    this.load()
  },
  activated() {
    //this.$route.name === 'suporte.cliente.faturas' && this.load()
  },
  watch: {
  },
  methods: {
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || (!this.table.filters.data2 || this.table.filters.data2.length < 10)) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)
      this.table.filters.search && extraFilters.push(`&search=${String(this.table.filters.search).replace('#', '_hash-')}`)
      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.active && extraFilters.push(`&active=${this.table.filters.active}`)
      this.table.filters.status && extraFilters.push(`&status=${this.table.filters.status.id}`)

      if (this.extraFilters && Array.isArray(this.extraFilters)) {
        this.extraFilters.map(ef => {
          extraFilters.push('&' + ef)
        })
      }

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'dataInicio'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : true}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, false)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
            this.$emit('load', data)
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set(this.listName, newConfig)
      this.listStorage = newConfig
    }
  }
}
</script>
